// @reduxjs
import DashboardUserService from '@/services/dashboard/user/UserService/UserService';
import { DashboardUserGetNotificationsListResponseDto } from '@/services/dashboard/user/UserService/dto';
// store
import { dispatch } from '../../store';
import {
  ADD_NOTIFICATION,
  CLEAR_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  SET_NOTIFICATION_LIST,
} from './notification';

export function setNotificationList(
  notificationsList: DashboardUserGetNotificationsListResponseDto[]
) {
  dispatch(SET_NOTIFICATION_LIST(notificationsList));
}

export function push(notificationObject: DashboardUserGetNotificationsListResponseDto) {
  dispatch(ADD_NOTIFICATION({ ...notificationObject, notRemote: true }));
}

export function remove(notificationId: number) {
  dispatch(DELETE_NOTIFICATION(notificationId));
}

export async function read(notificationId: number, notRemote?: boolean) {
  dispatch(READ_NOTIFICATION(notificationId));
  if (!notRemote)
    await DashboardUserService.updateNotification(notificationId, {
      readAt: new Date(Date.now()),
    });
}

export async function readAll(notRemote?: boolean) {
  dispatch(READ_ALL_NOTIFICATION());
  if (!notRemote) DashboardUserService.putNotificationReadAll();
}

export function clearAll() {
  dispatch(CLEAR_ALL_NOTIFICATION());
}
