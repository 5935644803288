// redux
import { modalActions } from '@/redux/slices/modal/modal';
import { remove } from '@/redux/slices/notification/utils';
import { dispatch } from '@/redux/store';

export const CREATE_STARTUP = 'CREATE_STARTUP';

export function createStartup(notificationId) {
  dispatch(modalActions.SET_CREATE_STARTUP({ open: true }));
  remove(notificationId);
}
