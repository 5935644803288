// react
import { useContext } from 'react';
// contexts
import { LayoutContext } from '@/contexts/LayoutContext';

export default function useSidebar() {
  const context = useContext(LayoutContext);

  if (!context) throw new Error('Layout context must be use inside LayoutProvider');

  return context;
}
