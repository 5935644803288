'use client';

// react
import { useEffect, useState } from 'react';
// @mui
import { AppBar } from '@mui/material';
// hooks
import { useAuth, useResponsive } from '@/hooks';
// components
import { LoadingScreen } from '@/components/minimal';
//
import DesktopHeader from './desktop-header';
import MobileHeader from './MobileHeader';

// --------------------------------------------------------------------------

export default function MainHeader() {
  const isDesktop = useResponsive('up', 'lg');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { isLoading } = useAuth();

  if (!isMounted) return null;
  if (isLoading) return <LoadingScreen />;

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'white', zIndex: 1201 }}>
      {isDesktop ? <DesktopHeader /> : <MobileHeader />}
    </AppBar>
  );
}
