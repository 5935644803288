// react
import { Suspense } from 'react';
// @mui
import { Stack, Typography, Link as MuiLink, Divider, Box } from '@mui/material';
// next
import Link from 'next/link';

export default function NavigationItems({ item = {} }) {
  const { title, children } = item;

  return (
    <Stack width={200} alignItems="flex-start">
      <Box>
        <Typography variant="h4" fontWeight="bolder" mb={1}>
          {title}
        </Typography>
        <Divider sx={{ borderColor: (theme) => theme.palette.grey[400], my: 1 }} />
      </Box>
      {children?.map((link) => (
        <NavigationItem key={link?.href} link={link} />
      ))}
    </Stack>
  );
}

function NavigationItem({ link = {} }) {
  const { title, href } = link;

  return (
    <Suspense fallback={<>Loading...</>}>
      <MuiLink
        component={Link}
        prefetch={false}
        href={href}
        variant="body2"
        color={(theme) => theme.palette.grey[500]}
      >
        {title}
      </MuiLink>
    </Suspense>
  );
}
