// @mui
import { Button, Typography } from '@mui/material';

export default function PinkButton({ onClick, text }) {
  return (
    <Button
      onClick={onClick}
      className="heroButtonStyle pinkButtonStyle"
      sx={{
        backgroundColor: '#ee2960',
        '&:hover': {
          backgroundColor: '#ee2960',
        },
      }}
    >
      <Typography className="heroButtonText">{text}</Typography>
    </Button>
  );
}
