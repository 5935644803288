'use client';

// react
import { useState, useEffect } from 'react';
// next
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
// @mui
import { Box, Grid, Button, Menu, Typography, IconButton, Stack } from '@mui/material';
// components
import { PinkButton } from '@/components/common/buttons';
import Iconify from '@/components/minimal/iconify';
import { ScaleupstreamLogo } from '@/components/common';
// hooks
import { useAuth, useModal, useSidebar } from '@/hooks';
// config
import headerLinksNavigation from '@/config/header-links-navigation.config';
//
const AccountMenu = dynamic(() => import('./AccountMenu'));
const NavigationItem = dynamic(() => import('./NavigationItem'));
const SelectUserGroup = dynamic(() => import('./SelectUserGroup'));

export default function MobileHeader() {
  const {
    login: { setOpen: setLoginModalOpen },
    signup: { setOpen: setSignupModalOpen },
  } = useModal();

  const { user } = useAuth();

  const pathname = usePathname();

  const {
    toggleSidebar,
    showHamburgerMenu,
    state: { isShowHamburgerMenu },
  } = useSidebar();

  const [resMenuVisible, setResMenuVisible] = useState(false);
  const [hamburgerButtonAnchor, setHamburgerButtonAnchor] = useState(null);

  useEffect(() => {
    showHamburgerMenu(
      pathname?.startsWith('/page-management') || pathname?.startsWith('/profile/settings')
    );
  }, [pathname, showHamburgerMenu]);

  const stateControl = (name, value) => {
    let newValue = null;
    if (name === 'resMenuVisible' && !value) {
      newValue = !resMenuVisible;
      setResMenuVisible(newValue);
    }
  };

  const handleLogin = () => {
    setLoginModalOpen(true);
  };

  const handleSignup = () => {
    setSignupModalOpen(true);
  };
  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          boxShadow: '0px 10px 24px 0px #0000001A',
          width: '100%',
          py: 1,
        }}
        container
      >
        {isShowHamburgerMenu && (
          <Grid container item xs={1} alignItems={'center'}>
            <IconButton
              onClick={toggleSidebar}
              style={{ border: 'none', backgroundColor: 'white' }}
            >
              <Iconify icon="eva:menu-2-fill" sx={{ fontSize: '25px' }} />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={3} px={1}>
          <Box sx={{ m: 1 }}>
            <ScaleupstreamLogo alt="ScaleupStream" />
          </Box>
        </Grid>

        <Grid item xs={!user ? (isShowHamburgerMenu ? 6 : 7) : 2} />
        <Grid
          container
          item
          xs={!user ? 2 : isShowHamburgerMenu ? 6 : 7}
          justifyContent={'end'}
          alignItems={'end'}
          px={1}
        >
          {!user ? (
            <Button
              id="hamburger-button"
              aria-controls={resMenuVisible ? 'hamburger-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={resMenuVisible ? 'true' : undefined}
              onClick={(e) => {
                setHamburgerButtonAnchor(e.currentTarget);
                stateControl('resMenuVisible');
              }}
              style={{ border: 'none', backgroundColor: 'white' }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <i className="icon-menu pinkNavBarItem" style={{ fontSize: '25px' }} />
            </Button>
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <AccountMenu additionalLinks={headerLinksNavigation} />
            </Stack>
          )}
        </Grid>
        {user && Boolean(user?.userGroups?.length) && <SelectUserGroup />}
      </Grid>

      <Menu
        id="hamburger-menu"
        aria-labelledby="hamburger-button"
        sx={{ display: { lg: 'none', md: 'flex' } }}
        open={resMenuVisible && !user}
        onClose={() => {
          setResMenuVisible(false);
        }}
        anchorEl={hamburgerButtonAnchor}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { m: 0 } }}
      >
        <Grid container direction="column" className="navbarPagesContainer" px={2}>
          {headerLinksNavigation?.map((item, i) => (
            <NavigationItem item={item} key={`mobile-nav-item-${i}`} />
          ))}
          <Grid item marginLeft={-0.5} justifyContent="center" display="flex">
            <Button
              className="navbarButton pinkNavBarItem"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              variant="text"
              onClick={() => alert('test')}
            >
              <i className="icon-globe " style={{ fontSize: '15px' }} />
              <Typography variant="navbar">English</Typography>
            </Button>
          </Grid>
          {!user && (
            <>
              <Grid item marginLeft={-0.5} justifyContent="center" display="flex">
                <Button
                  className="navbarButton"
                  variant="text"
                  onClick={handleLogin}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  <i className="icon-user pinkNavBarItem" style={{ fontSize: '15px' }} />
                  <Typography variant="navbar" className="pinkNavBarItem">
                    Log In
                  </Typography>
                </Button>
              </Grid>
              <Grid item marginLeft={-0.6} marginTop={1} justifyContent="center" display="flex">
                <PinkButton text="Sign Up" onClick={handleSignup} />
              </Grid>
            </>
          )}
        </Grid>
      </Menu>
    </>
  );
}
