import { BaseService } from 'src/services/BaseService';

class DashboardInvestorsSubmissionsService extends BaseService {
  getSubmissions(config = {}) {
    return this.get(``, config);
  }
  getSubmissionsSettings() {
    return this.get(`settings`);
  }
  getSubmissionsStatistics() {
    return this.get(`statistics`);
  }
  getSubmission(submissionId) {
    return this.get(`${submissionId}`);
  }
  putSubmissionReject(submissionId, data) {
    return this.put(`${submissionId}/reject`, data);
  }
  postSubmissionInviteToPitchEvent(submissionId, userId, contentId) {
    return this.post(`${submissionId}/invite-to-pitch`, { userId, contentId });
  }
  putSubmissionsWatchList(submissionId) {
    return this.put(`${submissionId}/watch-list`);
  }
  putPitchEventSubmissionStatus(submissionId, contentId, userId, status) {
    return this.put(`${submissionId}/content/${contentId}`, { status, userId });
  }
}

export default new DashboardInvestorsSubmissionsService('dashboard/investors/submissions');
