// utils
import { setUserGroup } from '@/utils/userGroup';
// redux
import { modalActions } from '@/redux/slices/modal/modal';
import { dispatch } from '@/redux/store';

export const REGISTERED_TO_EVENT = 'REGISTERED_TO_EVENT';

export function registeredToEvent(notificationId, { userGroupId, userGroupType, contentId }, user) {
  const userGroup = user?.userGroups?.find(({ id }) => id == userGroupId);
  if (!userGroup) return;
  setUserGroup(userGroup);

  dispatch(
    modalActions.SET_REGISTERED_USERS({
      open: true,
      props: {
        userGroupId: Number(userGroupId),
        userGroupType: Number(userGroupType),
        contentId: Number(contentId),
      },
    })
  );
}
