'use client';

import { useEffect, useState } from 'react';

type Options = {
  once?: boolean;
};

export default function useOnScreen(ref: any, options: Options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [isExecuted, setIsExecuted] = useState(false);

  useEffect(() => {
    if (!ref) return;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (isExecuted && options?.once) return;
        setIsExecuted(true);
      } else {
        if (isExecuted && options?.once) return;
      }
      setIntersecting(entry.isIntersecting);
    });
    observer.observe(ref?.current);
    return () => observer.disconnect();
  }, [isExecuted, options?.once, ref]);

  if (!ref) return true;

  return isIntersecting;
}
