import { forwardRef } from 'react';
// prop-types
import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// img
import { smeImage } from './img';
// components
import { Image } from '@/components/common';

// ----------------------------------------------------------------------

const SMEIcon = forwardRef((props, ref) => {
  return (
    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      <Box ref={ref}>
        <Image src={smeImage.src} alt="Service Provider" width={20} height={20} />
      </Box>
    </Box>
  );
});

SMEIcon.propTypes = {
  sx: PropTypes.object,
};

export default SMEIcon;
