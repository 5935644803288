// next
import { paths } from '@/routes/paths';

export const ADDED_TO_INCUBATOR_COHORT = 'ADDED_TO_INCUBATOR_COHORT';

export function addedToIncubatorCohort(notificationId, params) {
  const { incubatorId } = params;

  window.location = `${paths.incubators.root}/${incubatorId}`;
}
