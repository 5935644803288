import { paths } from '@/routes/paths';

export const navigationLinks = [
  // {
  //   title: 'About',
  //   children: [
  //     {
  //       title: 'About Us',
  //       href: URL_SCHEMA.about_us,
  //     },
  //     {
  //       title: 'Disclaimer',
  //       href: URL_SCHEMA.disclaimer,
  //     },
  //     {
  //       title: 'FAQ',
  //       href: URL_SCHEMA.faq,
  //     },
  //   ],
  // },
  {
    title: 'Explore Contents',
    children: [
      {
        title: 'Announcements',
        href: paths.announcements,
      },
      {
        title: 'Events',
        href: paths.events,
      },
      {
        title: 'Programs',
        href: paths.programs,
      },
      {
        title: 'Marketplace',
        href: paths.products.root,
      },
      //{
        //title: 'Services',
        //href: paths.services,
      //},
    ],
  },
  {
    title: 'Explore Pages',
    children: [
      {
        title: 'Investors',
        href: paths.investors.root,
      },
      {
        title: 'Startups',
        href: paths.startups.root,
      },
      {
        title: 'Incubators',
        href: paths.incubators.root,
      },
      {
        title: 'Service Providers',
        href: paths.smes.root,
      },
      {
        title: 'Institutions',
        href: paths.institutions.root,
      },
    ],
  },
];
