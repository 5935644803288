// utils
import { CONTENT_TYPE } from '@/enums';
import getContentHref from '@/utils/contents/getContentHref';

export const ACCEPTED_TO_PITCH = 'ACCEPTED_TO_PITCH';

export function acceptedToPitch(notificationId, params) {
  const { contentId } = params;

  window.location = getContentHref(contentId, CONTENT_TYPE.PITCH_EVENT);
}
