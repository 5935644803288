import { paths } from '@/routes/paths';
import { CONTENT_TYPE } from '@/enums/content-type.enum';

export default function getContentHref(contentId, contentType) {
  switch (contentType) {
    case CONTENT_TYPE.PROGRAM:
      return `${paths.programs}/${contentId}`;
    case CONTENT_TYPE.PRODUCT:
    case CONTENT_TYPE.PRODUCT_PACKAGE:
      return paths.products.detail(contentId);
    case CONTENT_TYPE.SERVICE:
    case CONTENT_TYPE.ONE_ON_ONE:
      return `${paths.services}/${contentId}`;
    default:
      return `${paths.events}/${contentId}`;
  }
}
