'use client';

// @mui
import { Box, Stack } from '@mui/material';
// hooks
import { useAuth, useShared } from '@/hooks';
// next
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
//
import MainHeader from './MainHeader';
import FooterSection from './FooterSection';
const VideoTutorialButton = dynamic(() => import('./VideoTutorialButton'));
const SubmitReport = dynamic(() => import('./SubmitReport'));
const SharePage = dynamic(() => import('./SharePage'));

// ----------------------------------------------------------------------

type MainLayoutProps = {
  children: React.ReactNode;
  noFooter?: boolean;
};

export default function MainLayout({ children, noFooter }: MainLayoutProps) {
  const { user } = useAuth();

  const asPath = usePathname();

  const { informationVideos } = useShared('informationVideos');
  const currentInformationVideos = informationVideos.filter(
    ({ path }: { path: string }) => path === asPath
  );
  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />
      {Boolean(currentInformationVideos?.length) && (
        <VideoTutorialButton currentInformationVideos={currentInformationVideos} />
      )}

      {children}
      {user && <SubmitReport />}
      {user && false && <SharePage />}

      <Box sx={{ flexGrow: 1 }} />
      {Boolean(!noFooter) && <FooterSection />}
    </Stack>
  );
}
