// react
import { useCallback, useState } from 'react';
// hooks
import { useAuth, useModal } from '@/hooks';
// enums
import { USER_GROUP_MEMBER_TYPE, USER_GROUP_TYPE } from '@/enums';
// @mui
import {
  ScatterPlot as StartupIcon,
  Stream as InvestorIcon,
  DomainAdd as IncubatorIcon,
  Add,
} from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
// assets
import { SMEIcon } from 'assets';
// components
import Iconify from '@/components/minimal/iconify';

export default function CreateUserGroupButton() {
  const { user } = useAuth();

  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState(null);

  const handleCreateMenuClose = useCallback(() => {
    setCreateMenuAnchorEl(null);
  }, []);
  const handleCreateMenuOpen = useCallback((event) => {
    setCreateMenuAnchorEl(event.target);
  }, []);

  const checkGroupType = useCallback(
    (groupType) => {
      if (groupType === USER_GROUP_TYPE.INVESTOR) {
        return Boolean(
          user?.userGroupMembers?.filter(
            ({ userGroup, userGroupMemberType }) =>
              userGroup.userGroupType === groupType &&
              userGroupMemberType === USER_GROUP_MEMBER_TYPE.OWNER
          ).length >= 5
        );
      }

      return Boolean(
        user?.userGroupMembers?.filter(
          ({ userGroup, userGroupMemberType }) =>
            userGroup.userGroupType === groupType &&
            userGroupMemberType === USER_GROUP_MEMBER_TYPE.OWNER
        ).length > 0
      );
    },
    [user?.userGroupMembers]
  );

  const {
    createInvestor: { setOpen: setCreateInvestorModalOpen, setProps: setCreateInvestorModalProps },
    createIncubator: {
      setOpen: setCreateIncubatorModalOpen,
      setProps: setCreateIncubatorModalProps,
    },
    createSME: { setOpen: setCreateSMEModalOpen, setProps: setCreateSMEModalProps },
    createInstitution: {
      setOpen: setCreateInstitutionModalOpen,
      setProps: setCreateInstitutionModalProps,
    },
    createStartup: { setOpen: setCreateStartupPricingModalOpen },
  } = useModal();

  return (
    <>
      <MenuItem onClick={handleCreateMenuOpen}>
        <ListItemIcon>
          <Add fontSize="medium" />
        </ListItemIcon>
        Create Page
      </MenuItem>

      <Menu
        anchorEl={createMenuAnchorEl}
        id="account-menu"
        open={Boolean(createMenuAnchorEl)}
        onClose={handleCreateMenuClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            maxHeight: '80vh',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {!checkGroupType(USER_GROUP_TYPE.INVESTOR) && (
          <MenuItem
            onClick={() => {
              setCreateInvestorModalOpen(true);
              setCreateInvestorModalProps({ unclosable: false });
            }}
          >
            <ListItemIcon>
              <InvestorIcon fontSize="small" sx={{ color: '#000' }} />
            </ListItemIcon>
            Create Investor Page
          </MenuItem>
        )}

        {!checkGroupType(USER_GROUP_TYPE.STARTUP) && (
          <MenuItem
            onClick={() => {
              setCreateStartupPricingModalOpen(true);
            }}
          >
            <ListItemIcon>
              <StartupIcon fontSize="small" sx={{ color: '#000' }} />
            </ListItemIcon>
            Create Startup Page
          </MenuItem>
        )}

        {!checkGroupType(USER_GROUP_TYPE.SME) && (
          <MenuItem
            onClick={() => {
              setCreateSMEModalOpen(true);
              setCreateSMEModalProps({ unclosable: false });
            }}
          >
            <ListItemIcon>
              <SMEIcon />
            </ListItemIcon>
            Become a Service Provider
          </MenuItem>
        )}

        {!checkGroupType(USER_GROUP_TYPE.INCUBATOR) && (
          <MenuItem
            onClick={() => {
              setCreateIncubatorModalOpen(true);
              setCreateIncubatorModalProps({ unclosable: false });
            }}
          >
            <ListItemIcon>
              <IncubatorIcon />
            </ListItemIcon>
            Load your incubator
          </MenuItem>
        )}

        {!checkGroupType(USER_GROUP_TYPE.INSTITUTION) && (
          <MenuItem
            onClick={() => {
              setCreateInstitutionModalOpen(true);
              setCreateInstitutionModalProps({ unclosable: false });
            }}
          >
            <ListItemIcon>
              <Iconify icon="fa:institution" />
            </ListItemIcon>
            Create Institution Page
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
