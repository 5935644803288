'use client';

// react
import { ReactNode, Suspense, useCallback, useEffect, useMemo } from 'react';
// @mui
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  ButtonBase,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
// hooks
import { useAuth, useSelectedUserGroup } from '@/hooks';
// utils
import { setUserGroup } from '@/utils/userGroup';
import getUserGroupTypeLink from '@/utils/userGroups/getUserGroupTypeLink';
import getUserGroupTypeLabel from '@/utils/userGroups/getUserGroupTypeLabel';
// next
import Link from 'next/link';
import { useRouter } from 'next/navigation';
// enums
import { USER_GROUP_TYPE } from '@/enums';
//
import UserGroupItem from './UserGroupItem';
import { ManageAccounts } from '@mui/icons-material';
import { AuthUserUserGroup } from '@/contexts/AuthContext';

const USER_GROUP_TYPE_PRIORTIES = {
  [USER_GROUP_TYPE.INVESTOR]: 0,
  [USER_GROUP_TYPE.STARTUP]: 1,
  [USER_GROUP_TYPE.INCUBATOR]: 2,
  [USER_GROUP_TYPE.SME]: 3,
  [USER_GROUP_TYPE.INSTITUTION]: 4,
};

export default function SelectUserGroup() {
  const { user } = useAuth();

  const { push } = useRouter();

  const userGroup = useSelectedUserGroup();

  const userGroups = useMemo(() => user?.userGroups, [user?.userGroups]);
  const groupedUserGroups: any = useMemo(
    () =>
      userGroups?.reduce((groupObj: { [key: number]: any }, userGroup) => {
        if (groupObj[userGroup?.userGroupType]?.length)
          groupObj[userGroup?.userGroupType].push(userGroup);
        else groupObj[userGroup?.userGroupType] = [userGroup];
        return groupObj;
      }, {}),
    [userGroups]
  );

  const handleUserGroupChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      const userGroup = userGroups?.find(({ id }) => id === e.target.value);
      setUserGroup(userGroup);
      if (!userGroup) {
        push('/');
      }
    },
    [push, userGroups]
  );

  useEffect(() => {
    if (!userGroup?.id && userGroups?.length) {
      const _userGroups = userGroups
        .slice(0)
        .sort(
          ({ status: a, userGroupType: c }, { status: b, userGroupType: d }) =>
            (a ?? 5) + USER_GROUP_TYPE_PRIORTIES[c] - ((b ?? 5) + USER_GROUP_TYPE_PRIORTIES[d])
        );
      const _userGroup = _userGroups[0];
      setUserGroup(_userGroup);
    }
  }, [userGroup, userGroups]);

  return (
    <Stack direction="row" flexDirection="row" alignItems="center">
      <FormControl
        sx={{
          m: 1,
          minWidth: 200,
          maxWidth: 320,
          ml: { xs: 0, md: 4 },
          '& > div:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
        }}
        size="small"
      >
        <InputLabel htmlFor="selected-user-group">Viewing As</InputLabel>
        <Select<number>
          id="selected-user-group"
          label="Viewing As"
          value={userGroup?.id}
          onChange={handleUserGroupChange}
          renderValue={() => <UserGroupItem userGroup={userGroup} selected />}
          variant="standard"
          size="small"
          sx={{ '&:before,&:after': { border: 'none' } }}
          inputProps={{ sx: { '&:hover': { border: 'none' } } }}
        >
          {Object.entries(groupedUserGroups ?? {})?.reduce(
            (renderArr: ReactNode[], [userGroupType, userGroups]: any) => [
              ...renderArr,
              <ListSubheader key={`select-user-group-option-group-${userGroupType}`}>
                {getUserGroupTypeLabel(Number(userGroupType))}
              </ListSubheader>,
              ...userGroups.map((userGroup: AuthUserUserGroup) => (
                <MenuItem
                  value={userGroup?.id}
                  dense
                  key={`select-user-group-option-${userGroup?.id}`}
                >
                  <UserGroupItem userGroup={userGroup} />
                </MenuItem>
              )),
            ],
            []
          )}
        </Select>
      </FormControl>
      {userGroup?.id && (
        <Tooltip title="Page Management">
          <span>
            <Suspense fallback={<>Loading...</>}>
              <Link
                prefetch={false}
                passHref
                legacyBehavior
                href={getUserGroupTypeLink(userGroup?.userGroupType, true) ?? ''}
              >
                <ButtonBase sx={{ ml: 1 }}>
                  <Stack direction="column" alignItems="center" justifyContent="center">
                    <ManageAccounts sx={{ color: 'black', width: 24, height: 24 }} />
                    <Typography fontSize="8px" color="black" fontWeight="700">
                      Manage
                    </Typography>
                  </Stack>
                </ButtonBase>
              </Link>
            </Suspense>
          </span>
        </Tooltip>
      )}
    </Stack>
  );
}
