'use client';

// @mui
import { Box, Button, Typography, Stack } from '@mui/material';
// components
import { PinkButton } from '@/components/common/buttons';
import { ScaleupstreamLogo } from '@/components/common';
// hooks
import { useAuth, useModal } from '@/hooks';
// config
import headerLinksNavigation from '@/config/header-links-navigation.config';
//
import SelectUserGroup from './SelectUserGroup';
import AccountMenu from './AccountMenu';
import NavigationItem from './NavigationItem';

export default function DesktopHeader() {
  const { user } = useAuth();

  const {
    login: { setOpen: setLoginModalOpen },
    signup: { setOpen: setSignupModalOpen },
  } = useModal();

  const handleLogin = () => {
    setLoginModalOpen(true);
  };

  const handleSignup = () => {
    setSignupModalOpen(true);
  };

  return (
    <>
      {user && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}
          px={4}
        >
          <Box sx={{ flex: 1 }}>{Boolean(user?.userGroups?.length) && <SelectUserGroup />}</Box>
          <Box>
            <AccountMenu />
          </Box>
        </Stack>
      )}
      <Stack
        direction={'row'}
        px={4}
        py={1}
        alignItems="center"
        justifyContent="center"
        sx={{
          boxShadow: '0px 10px 24px 0px #0000001A',
        }}
      >
        <Box sx={{ my: 1 }}>
          <ScaleupstreamLogo />
        </Box>

        <Stack flex={1} direction="row" justifyContent="center" alignItems="center">
          {headerLinksNavigation?.map((item, i) => (
            <NavigationItem item={item} key={`menu-${i}`} />
          ))}
        </Stack>
        {!user && (
          <>
            <Button
              className="navbarButton pinkNavBarItem"
              variant="text"
              onClick={handleLogin}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              <i className="icon-user" style={{ fontSize: '15px' }} />
              <Typography variant={'navbar' as any}>Login</Typography>
            </Button>
            <PinkButton text="Sign Up" onClick={handleSignup} />
          </>
        )}
      </Stack>
    </>
  );
}
