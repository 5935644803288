// @mui
import { CalendarMonth, Home, Business, People, Campaign } from '@mui/icons-material';
// constants
import { paths } from '@/routes/paths';

const headerLinksNavigation = [
  {
    url: '/',
    icon: <Home fontSize="medium" />,
  },
  {
    name: 'Announcements',
    url: paths.announcements,
    icon: <Campaign fontSize="medium" sx={{ color: 'primary.main' }} />,
  },
  {
    name: 'Startups',
    url: paths.startups.root,
    icon: <Business fontSize="medium" sx={{ color: '#52A7C9' }} />,
  },
  {
    name: 'Investors',
    url: paths.investors.root,
    icon: <People fontSize="medium" sx={{ color: '#52A7C9' }} />,
  },
  {
    name: 'Marketplace',
    url: paths.products.root,
    icon: <CalendarMonth fontSize="medium" sx={{ color: '#52A7C9' }} />,
  },
  {
    name: 'Events',
    url: paths.events,
    icon: <CalendarMonth fontSize="medium" sx={{ color: '#52A7C9' }} />,
  },
];

export default headerLinksNavigation;