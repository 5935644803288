// react
import { useMemo } from 'react';
// components
import { ScaleupstreamDarkLogo } from '@/components/common';
// @mui
import { Container, Box, Stack, Typography } from '@mui/material';
//
import { navigationLinks } from './navigation-links.config';
import NavigationItems from './NavigationItems';
import SocialMediaLinks from './SocialMediaLinks';

export default function FooterSection() {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box sx={{ backgroundColor: 'black', color: (theme) => theme.palette.grey[400] }}>
      <Container
        sx={{
          py: 1,
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          spacing={3}
          py={1}
          my={1}
          useFlexGap flexWrap="wrap"
        >
          <Box sx={{ m: 2, width: 300, textAlign: 'left' }}>
            <ScaleupstreamDarkLogo />
            <Typography variant="body2">353 Sacramento St. Floor 18</Typography>
            <Typography variant="body2">San Francisco, 94111 California, U.S.</Typography>
            <Typography variant="body2" my={1}>
              Follow Us
            </Typography>
            <SocialMediaLinks />
        
          </Box>
          {navigationLinks?.map((item, index) => (
            <NavigationItems item={item} index={index} key={`navigation-item-index-${index}`} />
          ))}
            <Box sx={{ m: 2, textAlign: 'left' }}>
              <Typography variant="body2">
              ScaleupStream is not an investor and does not provide funding or legal guidance. We provide an environment and ecosystem to advance innovation.
              </Typography>   
              <Typography sx={{ m: 2, width: 300, textAlign: 'center' }}variant="body2">
             
              </Typography>
              <Typography sx={{ mt: 4, textAlign: 'center'}} variant="body2">
              © {currentYear} Scaleupstream, Inc.
            </Typography>
            </Box>
        </Stack>
      </Container>
    </Box>
  );
}
