// redux
import { modalActions } from '@/redux/slices/modal/modal';
import { dispatch } from '@/redux/store';
import { remove } from '@/redux/slices/notification/utils';

export const CREATE_INVESTOR = 'CREATE_INVESTOR';

export function createInvestor(notificationId) {
  dispatch(modalActions.SET_CREATE_INVESTOR({ open: true }));
  remove(notificationId);
}
