'use client';

// react
import { Suspense, useCallback, useState } from 'react';
// @mui
import { ExpandMore } from '@mui/icons-material';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
// next
import Link from 'next/link';
// hooks
import { useResponsive } from '@/hooks';

export default function NavigationItem({ item }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const isDesktop = useResponsive('up', 'lg');

  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto!important';
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!item.children)
    return (
      <Suspense fallback={<>Loading...</>}>
        <Link prefetch={false} href={item.url}>
          <Typography
            sx={{
              transition: 'color 0.3s',
              fontWeight: 400,
              fontSize: 16,
              mx: 1,
              '&:hover': {
                transition: 'color 0.3s',
                color: 'pink.main',
              },
            }}
          >
            {item.name ?? item.icon}
          </Typography>
        </Link>
      </Suspense>
    );

  return (
    <Suspense fallback={<>Loading...</>}>
      <Button
        id="navigation-item-button"
        aria-controls={open ? 'navigation-item-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseEnter={isDesktop ? handleClick : () => {}}
        variant="black"
        className="navbarButton"
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Typography variant="navbar" className="pinkNavBarItem">
          {item.name ?? item.icon}
        </Typography>
        <ExpandMore />
      </Button>
      <Menu
        id="navigation-item-menu"
        aria-labelledby="navigation-item-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          ...(isDesktop && { onMouseLeave: handleClose }),
        }}
      >
        {item.children.map((subitem, k) => (
          <MenuItem key={`sub-menu-${subitem.url}-${k}`} className="pinkNavBarItem">
            <Link prefetch={false} href={subitem.url} passHref legacyBehavior>
              <Stack alignItems="center" direction="row" spacing={2}>
                {subitem.icon}
                <Typography variant="navbar">{subitem.name}</Typography>
              </Stack>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Suspense>
  );
}
