'use client';

// react
import { useCallback, useEffect, useState } from 'react';
//
import useOnScreen from './useOnScreen';

export default function useFetch(
  fetchFn: () => Promise<boolean | void>,
  dependencies: any = [],
  ref?: any
) {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const isVisible = useOnScreen(ref, { once: true });

  const runFetch = useCallback(async () => {
    setLoading(true);
    if (!isVisible) return;
    const fetchSuccess = await fetchFn();
    if (!fetchSuccess) setNotFound(true);
    setLoading(false);
  }, [fetchFn, isVisible]);

  useEffect(() => {
    runFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runFetch, ...dependencies]);

  return { loading, notFound, mutate: runFetch };
}
