// @mui
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';
// prop-types
import PropTypes from 'prop-types';

export default function Dropdown({ options, onChange, value, label, renderOption, ...other }) {
  return (
    <FormControl {...other}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select label={label} value={value} onChange={onChange}>
        {options.map((option, index) =>
          !renderOption ? (
            <MenuItem key={index} value={option.value ?? option}>
              {option.text ?? option}
            </MenuItem>
          ) : (
            renderOption(option)
          )
        )}
      </Select>
    </FormControl>
  );
}

const ValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const OptionType = PropTypes.shape({
  text: PropTypes.string,
  value: ValueType,
});

Dropdown.propTypes = {
  options: PropTypes.arrayOf(OptionType),
  onChange: PropTypes.func,
  value: ValueType,
  label: PropTypes.string,
};
