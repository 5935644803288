'use client';

// redux
import { dispatch, getState } from '@/redux/store';
import { userGroupActions } from '@/redux/slices/user-group';
import { setCookie } from 'cookies-next';

export function setUserGroup(userGroup) {
  if (!userGroup) {
    dispatch(userGroupActions.RESET_USER_GROUP());
  } else {
    dispatch(userGroupActions.SET_USER_GROUP(userGroup));
    setCookie('usergroupid', userGroup.id, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
  }
}

export function getUserGroupId() {
  return getState()?.userGroup?.id;
}
export function getUserGroup() {
  return getState()?.userGroup;
}
