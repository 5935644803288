//
import axios from './axios';
import { deleteCookie, setCookie } from 'cookies-next';

// ----------------------------------------------------------------------

const setSession = (accessToken?: string | null) => {
  if (!axios) return;
  if (accessToken) {
    setCookie('accessToken', accessToken, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
    });
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    deleteCookie('accessToken');
    deleteCookie('userGroupId');
    sessionStorage.clear();
    delete axios.defaults.headers.common.Authorization;
  }
};

export { setSession };
