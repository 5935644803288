// react
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { Box, List, Badge, Divider, Typography, ListSubheader, Stack, Button } from '@mui/material';
// components
import { MenuPopover } from '@/components/minimal';
import Iconify from '@/components/minimal/iconify';
import Scrollbar from '@/components/minimal/scroll-bar';
import { IconButtonAnimate } from '@/components/minimal/animate';
import { Loading } from '@/components/common';
// hooks
import { useFetch } from '@/hooks';
// services
import { DashboardUserService } from '@/services';
// constants
import { paths } from '@/routes/paths';
import { FETCH_NOTIFICATIONS_EVENT } from '@/constants/window-events/fetch-notifications.window-event.constant';
// next
import Link from 'next/link';
import { usePathname } from 'next/navigation';
// notifications
import { useNotification } from '@/notifications';
//
import NotificationItem from './NotificationItem';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const pathname = usePathname();

  const { notifications, unreadNotificationsCount, readAll, setNotificationList } =
    useNotification();

  const unreadNotifications = useMemo(
    () => notifications.filter(({ readAt }: any) => !readAt),
    [notifications]
  );

  const [open, setOpen] = useState(null);

  const handleClose = () => {
    setOpen(null);
  };

  const fetchNotifications = useCallback(async () => {
    const { success, data } = await DashboardUserService.getNotificationsList(1, 5, true);
    if (success) {
      setNotificationList(data ?? []);
    }
    return true;
  }, [setNotificationList]);

  const { loading, mutate } = useFetch(fetchNotifications, [pathname]);

  const handleMarkAllAsRead = async () => {
    await readAll();
    mutate();
  };

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  useEffect(() => {
    function fetch() {
      mutate();
    }
    document.addEventListener(FETCH_NOTIFICATIONS_EVENT, fetch);
    return () => document.removeEventListener(FETCH_NOTIFICATIONS_EVENT, fetch);
  }, [mutate]);

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        disabledArrow={undefined}
      >
        {loading && (
          <Box sx={{ display: 'flex', height: 400 }}>
            <Loading />
          </Box>
        )}
        {!loading && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">Notifications</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  You have {unreadNotificationsCount} unread notifications
                </Typography>
              </Box>

              {unreadNotificationsCount > 0 && (
                <Button
                  variant="text"
                  color="primary"
                  sx={{ fontSize: 10, p: '4px' }}
                  onClick={handleMarkAllAsRead}
                >
                  Mark all as read
                </Button>
              )}
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              {unreadNotifications.length > 0 && (
                <List
                  disablePadding
                  subheader={
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      Unread Notifications
                    </ListSubheader>
                  }
                >
                  {unreadNotifications.slice(0, 5).map((notification: any) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      handleClose={handleClose}
                    />
                  ))}
                </List>
              )}
            </Scrollbar>
            <Stack p={2}>
              <Link prefetch={false} href={paths.profile.notifications} passHref legacyBehavior>
                <Button sx={{ textAlign: 'center' }}>View All</Button>
              </Link>
            </Stack>
          </>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------
