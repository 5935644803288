import { BaseService } from 'src/services/BaseService';

class DashboardInvestorsService extends BaseService {
  postApply(investorData) {
    return this.post('apply', investorData);
  }
  getWithPendingSubmissions() {
    return this.get('with-pending-submissions');
  }
  getPageBuilder() {
    return this.get(``);
  }
  putPageBuilder(investorData) {
    return this.put(``, investorData);
  }
}

export default new DashboardInvestorsService('dashboard/investors');
