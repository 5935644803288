'use client';

// prop-types
import dynamic from 'next/dynamic';
//
import MainLayout from './main';
const PageManagementLayout = dynamic(() => import('./page-management'));
const ProfileSettingsLayout = dynamic(() => import('./profile-settings'));
const LogoOnlyLayout = dynamic(() => import('./LogoOnlyLayout'));

// ----------------------------------------------------------------------

type LayoutProps = {
  children: React.ReactNode;
  variant?: 'page-management' | 'main' | 'logoOnly' | 'profile-settings';
};

export default function Layout({ variant = 'main', children }: LayoutProps) {
  switch (variant) {
    case 'main':
      return <MainLayout>{children}</MainLayout>;

    case 'logoOnly':
      return <LogoOnlyLayout>{children}</LogoOnlyLayout>;

    case 'page-management':
      return (
        <MainLayout noFooter>
          <PageManagementLayout>{children}</PageManagementLayout>
        </MainLayout>
      );
    case 'profile-settings':
      return (
        <MainLayout noFooter>
          <ProfileSettingsLayout>{children}</ProfileSettingsLayout>
        </MainLayout>
      );

    default:
      return <MainLayout>{children}</MainLayout>;
  }
}
