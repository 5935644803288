// react
import { useState, useEffect, useCallback } from 'react';
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
// components
import { Loading } from '@/components/common/';
import { Dropdown } from '@/components/common/dropdowns/';
// services
import { DashboardInvestorsService, DashboardInvestorsSubmissionsService } from '@/services';
// hooks
import { useFetch, useAuth } from '@/hooks';
// enums
import { SUBMISSIONS_STATUS } from '@/enums';
// utils
import { setUserGroup } from '@/utils/userGroup';
// next
import { useRouter } from 'next/navigation';
//
import SubmissionItem from './SubmissionItem';

// ---------------------------------------------------------------------

const style = {
  minWidth: { xs: '350px', md: '750px' },
  minHeight: '200px',
  borderRadius: '20px',
  bgcolor: '#FFFFFF',
  p: 4,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
};
const styles = {
  paddingTop: '3%',
  overflowX: 'hidden',
};

// ---------------------------------------------------------------------

export default function PendingSubmissionsModal() {
  const { user } = useAuth();

  const [open, setOpen] = useState(true);

  const [activeUserGroup, setActiveUserGroup] = useState<any>(null);
  const [investorsWithPendingSubmissions, setInvestorsWithPendingSubmissions] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  const { push } = useRouter();

  const handleClose = useCallback(() => {
    sessionStorage?.setItem('pendingSubmissionsShown', 'true');
    setOpen(false);
  }, []);

  useEffect(() => {
    if (
      investorsWithPendingSubmissions?.reduce((sum, { pendingCount }) => sum + pendingCount, 0) &&
      !sessionStorage?.getItem('pendingSubmissionsShown')
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [investorsWithPendingSubmissions]);

  const handleActiveUserGroupChange = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const userGroupId = event.target.value;
      setActiveUserGroup(investorsWithPendingSubmissions?.find(({ id }) => id === userGroupId));
      const { success: submissionsSuccess, data: submissionsData = [] } =
        await DashboardInvestorsSubmissionsService.getSubmissions({
          headers: { UserGroupId: userGroupId },
        });
      if (submissionsSuccess) {
        setSubmissions(
          submissionsData?.filter(
            ({ submission }: any) => submission?.status === SUBMISSIONS_STATUS.UNDECIDED
          )
        );
      }
    },
    [investorsWithPendingSubmissions]
  );

  const handleGoToSubmissions = useCallback(() => {
    setUserGroup(activeUserGroup);
    push('/page-management/investor/submissions');
    handleClose();
  }, [activeUserGroup, handleClose, push]);

  const fetchInvestorsWithPendingSubmissions = useCallback(async () => {
    if (!sessionStorage?.getItem('pendingSubmissionsShown') && user) {
      const { success, data = [] } = await DashboardInvestorsService.getWithPendingSubmissions();
      if (success && data?.length) {
        data?.sort((a: any, b: any) => b.pendingCount - a.pendingCount);
        const { success: submissionsSuccess, data: submissionsData = [] } =
          await DashboardInvestorsSubmissionsService.getSubmissions({
            headers: { UserGroupId: data[0].id },
          });
        if (submissionsSuccess) {
          setSubmissions(
            submissionsData?.filter(
              ({ submission }: any) => submission?.status === SUBMISSIONS_STATUS.UNDECIDED
            )
          );
          setActiveUserGroup(data[0]);
          setInvestorsWithPendingSubmissions(data);
        }
      }
    }
    return true;
  }, [user]);

  const { loading } = useFetch(fetchInvestorsWithPendingSubmissions);

  if (loading)
    return (
      <Modal open={open} onClose={handleClose} sx={styles}>
        <Box sx={style}>
          <Loading />
        </Box>
      </Modal>
    );
  return (
    <Modal open={open} onClose={handleClose}>
      <Card sx={style}>
        <CardHeader
          title={<Typography variant="h5">Welcome Back!</Typography>}
          action={
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Dropdown
                label="Investor:"
                value={activeUserGroup?.id}
                options={investorsWithPendingSubmissions?.filter(
                  ({ pendingCount }) => pendingCount
                )}
                renderOption={({ id, title }: any) => (
                  <MenuItem key={`investor-with-pending-${id}`} value={id}>
                    {title}
                  </MenuItem>
                )}
                onChange={handleActiveUserGroupChange}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="subtitle1" fontWeight="normal" textAlign="left">
                While you were gone, these startups
                {Boolean(activeUserGroup?.pendingCount - 3 > 0) &&
                  ` and ${activeUserGroup?.pendingCount - 3} more`}{' '}
                have applied to you for investment. Check them out and more on your submissions
                page.
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {submissions?.slice(0, 3).map((submission: any) => (
                <Grid item xs={12} md={4} key={`submission-item-${submission?.id}`}>
                  <SubmissionItem submission={submission} />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} container direction="row">
              <Button variant="contained" sx={{ mx: 'auto' }} onClick={handleGoToSubmissions}>
                Go to Submissions
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}
