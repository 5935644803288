import { paths } from '@/routes/paths';
import { USER_GROUP_TYPE } from '@/enums';

export default function getUserGroupTypeLink(userGroupType, isPageManagement = false) {
  switch (userGroupType) {
    case USER_GROUP_TYPE.INVESTOR:
      return isPageManagement ? paths.page_management.investor.builder : paths.investors.root;
    case USER_GROUP_TYPE.STARTUP:
      return isPageManagement ? paths.page_management.startup.builder : paths.startups.root;
    case USER_GROUP_TYPE.SME:
      return isPageManagement ? paths.page_management.sme.builder : paths.smes.root;
    case USER_GROUP_TYPE.INSTITUTION:
      return isPageManagement ? paths.page_management.institution.builder : paths.institutions.root;
    case USER_GROUP_TYPE.INCUBATOR:
      return isPageManagement ? paths.page_management.incubator.builder : paths.incubators.root;
  }
}
