// redux
import { SharedState, sharedActions } from '@/redux/slices/shared';
import { dispatch, useSelector } from '@/redux/store';
// services
import { SERVICES } from './services';

export default function useShared(items: string | string[]): SharedState {
  if (typeof items === 'string') {
    items = [items];
  }

  const state = useSelector((state) => state.shared) as SharedState;

  return items.reduce((items, item) => {
    const itemData = state[item];
    if (!itemData?.isInitialized && itemData?.length === 0) {
      const emptyArr: any = [];
      emptyArr.isInitialized = true;
      dispatch(sharedActions.updateData({ type: item, data: emptyArr }));
      SERVICES[item]()?.then(({ success, data }) => {
        if (success) {
          data.isInitialized = true;
          dispatch(sharedActions.updateData({ type: item, data }));
        }
      });
    }
    return { ...items, [item]: state[item] };
  }, {} as SharedState);
}
