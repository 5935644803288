import * as t from '.';

export default function getNotificationAction(action) {
  switch (action) {
    case t.CREATE_STARTUP:
      return t.createStartup;
    case t.CREATE_INVESTOR:
      return t.createInvestor;
    case t.COMPLETE_PAGE:
      return t.completePage;
    case t.SHOW_PENDING_SUBMISSIONS:
      return t.showPendingSubmissions;
    case t.INVITED_TO_EVENT:
    case t.INVITED_TO_SERVICE:
    case t.INVITED_TO_PROGRAM:
      return t.invitedToContent;
    case t.ACCEPTED_TO_PITCH:
      return t.acceptedToPitch;
    case t.ADDED_TO_WATCH_LIST:
      return t.addedToWatchList;
    case t.REGISTERED_TO_EVENT:
      return t.registeredToEvent;
    case t.SUBMITTED_TO_PITCH:
      return t.submittedToPitch;
    case t.ADDED_TO_INCUBATOR_COHORT:
      return t.addedToIncubatorCohort;
    case t.UNSCHEDULED_MEETING:
      return t.unscheduledMeeting;
    case t.INVESTOR_QUESTIONS_UPDATE:
      return t.investorQuestionsUpdate;
    default:
      return () => {};
  }
}
