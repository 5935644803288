import { USER_REFERRER_ACTION_TYPE } from '@/enums/user-referrer-action-type.enum';
import { BaseService } from '../../../BaseService';
import {
  DashboardUserGetUserGroupsResponseDto,
  DashboardUserContentCalendarDataDto,
  DashboardUserGetUserResponseDto,
  DashboardUserGetNotificationsListResponseDto,
} from './dto';

class DashboardUserService extends BaseService {
  getUser() {
    return this.get<DashboardUserGetUserResponseDto>('');
  }
  getUserGroups() {
    return this.get<DashboardUserGetUserGroupsResponseDto[]>('user-groups');
  }
  updateUser(data: any) {
    return this.put('', data);
  }
  deleteUser() {
    return this.delete('');
  }
  updatePassword(data: any) {
    return this.put('change-password', data);
  }
  becomeAffiliate(data: any) {
    return this.put('become-affiliate', data);
  }
  updateEmail(email: string) {
    return this.put('', { email });
  }
  getPurchasedContents(page = 1, size = 10, filter: any) {
    return this.post(`purchased-contents?page=${page}&size=${size}`, filter);
  }
  getAffiliateEarnings(page = 1, size = 10, action?: USER_REFERRER_ACTION_TYPE) {
    return this.get(`user-referrals`, {
      params: {
        page,
        size,
        action,
      },
    });
  }
  getCalendarData() {
    return this.get<DashboardUserContentCalendarDataDto[]>(`calendar`);
  }
  getNotificationsList(page = 1, size = 10, onlyUnread?: boolean) {
    return this.post<DashboardUserGetNotificationsListResponseDto[]>(
      `notifications/list?page=${page}&size=${size}`,
      { onlyUnread }
    );
  }
  updateNotification(notificationId: number, data: any) {
    return this.put(`notifications/${notificationId}`, data);
  }
  putNotificationReadAll() {
    return this.put(`notifications/read-all`);
  }
  getDeletionStatus() {
    return this.get(`deletion-status`);
  }
}

export default new DashboardUserService('dashboard/user');
