import { USER_GROUP_TYPE } from '@/enums';

export default function getUserGroupTypeLabel(userGroupType) {
  switch (userGroupType) {
    case USER_GROUP_TYPE.INVESTOR:
      return 'Investor';
    case USER_GROUP_TYPE.INCUBATOR:
      return 'Incubator';
    case USER_GROUP_TYPE.SME:
      return 'Service Provider';
    case USER_GROUP_TYPE.INSTITUTION:
      return 'Institution';
    case USER_GROUP_TYPE.STARTUP:
      return 'Startup';
    default:
      return 'Page';
  }
}
