// react
import { useMemo } from 'react';
// @mui
import { IconButton, Stack } from '@mui/material';
// components
import Iconify from '@/components/minimal/iconify';

const SocialMediaIconButton = ({ iconColor, children, ...other }) => (
  <IconButton
    {...other}
    target="_blank"
    sx={{
      backgroundColor: (theme) => theme.palette.grey[700],
      color: (theme) => theme.palette.grey[200],
      transition: 'all .5s',
      '&:hover': {
        backgroundColor: (theme) => theme.palette.grey[700],
        color: (theme) => theme.palette[iconColor],
      },
    }}
  >
    {children}
  </IconButton>
);

export default function SocialMediaLinks() {
  const linkedinHref = useMemo(() => 'https://www.linkedin.com/company/scaleupstream/', []);
  const youtubeHref = useMemo(() => 'https://www.youtube.com/@scaleupstream6776', []);
  const facebookHref = useMemo(() => 'https://www.facebook.com/scaleupstream', []);

  return (
    <Stack direction="row" spacing={1}>
      <SocialMediaIconButton href={linkedinHref} iconColor="linkedin">
        <Iconify icon="akar-icons:linkedin-fill" />
      </SocialMediaIconButton>
      <SocialMediaIconButton href={youtubeHref} iconColor="youtube">
        <Iconify icon="akar-icons:youtube-fill" />
      </SocialMediaIconButton>
      <SocialMediaIconButton href={facebookHref} iconColor="facebook">
        <Iconify icon="akar-icons:facebook-fill" />
      </SocialMediaIconButton>
    </Stack>
  );
}
