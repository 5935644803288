import { BaseService } from '../BaseService';
import {
  AuthPostGoogleAuthenticationResponseDto,
  AuthPostLinkedinAuthenticationResponseDto,
  AuthPostLoginRequestDto,
  AuthPostLoginResponseDto,
  AuthPostSignUpRequestDto,
  AuthPostSignUpResponseDto,
} from './dto';

class AuthService extends BaseService {
  postSignup(userData: AuthPostSignUpRequestDto, recaptchaToken?: string) {
    return this.post<AuthPostSignUpResponseDto>(
      'signup',
      {
        ...userData,
        contract: true,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }

  postLogin({ email, password, recaptchaToken }: AuthPostLoginRequestDto) {
    return this.post<AuthPostLoginResponseDto>(
      'login',
      {
        email,
        password,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }

  postLinkedinAuthentication(code: string, recaptchaToken?: string) {
    return this.post<AuthPostLinkedinAuthenticationResponseDto>(
      'linkedin-authentication',
      {
        token: code,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }

  postGoogleAuthentication(token: string, recaptchaToken?: string) {
    return this.post<AuthPostGoogleAuthenticationResponseDto>(
      'google-authentication',
      {
        token,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }

  postForgotPassword(email: string, recaptchaToken?: string) {
    return this.post(
      'forgot-password',
      {
        email,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }

  putResetPassword(password: string, email: string, code: string, recaptchaToken?: string) {
    return this.put(
      'reset-password',
      {
        password,
        email,
        code,
      },
      { headers: { recaptcha: recaptchaToken } }
    );
  }
}

export default new AuthService('auth');
