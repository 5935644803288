'use client';

import React, { Suspense, useEffect, useState } from 'react';
// next
import Link from 'next/link';
import dynamic from 'next/dynamic';
// @mui
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Button,
  Tooltip,
  Stack,
  IconButton,
  Collapse,
  ListItemButton,
  ListItem,
} from '@mui/material';
import {
  Logout,
  ExpandLess,
  ExpandMore,
  CalendarMonth,
  Settings,
  Paid,
  CalendarToday as EventIcon,
  PersonOutline as ProfileIcon,
  ManageAccounts,
  StarRateRounded,
  AddCircleRounded,
} from '@mui/icons-material';
// hooks
import { useAuth, useModal, useResponsive, useSelectedUserGroup } from '@/hooks';
// notifications
import { NotificationsPopover } from '@/notifications';
// constants
import { paths } from '@/routes/paths';
// components
import TextMaxLine from '@/components/minimal/text-max-line';
import { Image } from '@/components/common';
// utils
import getUserGroupTypeLink from '@/utils/userGroups/getUserGroupTypeLink';
//
import PendingSubmissionsModal from './PendingSubmissionsModal';
import Chat from './Chat';
import CreateUserGroupButton from './CreateUserGroupButton';
const Calendar = dynamic(() => import('./Calendar'));
const BecomeAffiliateModal = dynamic(() => import('./BecomeAffiliateModal'));

type AccountMenuProps = {
  additionalLinks?: {
    url: string;
    name: string;
    icon: React.ReactNode;
    children?: {
      url: string;
      name: string;
      icon: React.ReactNode;
    }[];
  }[];
};

export default function AccountMenu({ additionalLinks }: AccountMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [calendarAnchorEl, setCalendarAnchorEl] = useState<null | HTMLElement>(null);
  const [becomeAffiliateModalOpen, setBecomeAffiliateModalOpen] = useState(false);

  const {
    createProfile: { setOpen: setShowCreateProfileModal },
  } = useModal();

  const isDesktop = useResponsive('up', 'lg');

  const [mobileMenuCollapseOpenList, setMobileMenuCollapseOpenList] = useState<boolean[]>([]);
  const { user, logout } = useAuth();

  const userGroup = useSelectedUserGroup();

  useEffect(() => {
    if (!user?.firstName) {
      setShowCreateProfileModal(true);
    }
  }, [setShowCreateProfileModal, user?.firstName]);

  const open = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleCalendarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  return (
    <>
      {Boolean(calendarAnchorEl) && (
        <Calendar anchorEl={calendarAnchorEl} setAnchorEl={setCalendarAnchorEl} />
      )}
      {becomeAffiliateModalOpen && (
        <BecomeAffiliateModal
          open={becomeAffiliateModalOpen}
          setOpen={setBecomeAffiliateModalOpen}
        />
      )}
      <PendingSubmissionsModal />

      <Stack sx={{ alignItems: 'center', textAlign: 'center', ml: 2 }} direction="row" spacing={1}>
        <Chat />
        <Tooltip title="Calendar">
          <span>
            <IconButton onClick={handleCalendarClick}>
              <CalendarMonth />
            </IconButton>
          </span>
        </Tooltip>
        <NotificationsPopover />
        <Tooltip title="Account settings">
          <Button
            onClick={handleMenuClick}
            sx={{ color: 'text.primary', mt: '0px !important' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className="navbarButton"
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {user?.profilePhoto ? (
                <Image
                  alt={user?.firstName ?? ''}
                  src={user?.profilePhoto}
                  width={32}
                  height={32}
                  style={{ objectFit: 'contain' }}
                />
              ) : user?.firstName ? (
                user?.firstName[0]
              ) : (
                'C'
              )}
            </Avatar>
            {isDesktop && (
              <TextMaxLine
                variant={'navbar' as any}
                sx={{ fontSize: 14, paddingLeft: 1, maxWidth: 120, wordBreak: 'break-all' }}
                line={1}
              >
                {user?.firstName ? user?.firstName + ' ' + user?.lastName : 'Create Profile'}
              </TextMaxLine>
            )}
          </Button>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={menuAnchorEl}
        id="account-menu"
        open={open}
        onClose={handleMenuClose}
        disableScrollLock={true}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            maxHeight: '80vh',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box>
          <Suspense fallback={<>Loading...</>}>
            {additionalLinks?.map(({ url, name, icon, children }, index) => {
              const open = mobileMenuCollapseOpenList[index];
              return children?.length ? (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemButton
                      sx={{ pl: 0 }}
                      onClick={() => {
                        const arr: boolean[] = [...mobileMenuCollapseOpenList];
                        arr[index] = !open;
                        setMobileMenuCollapseOpenList(arr);
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      {name}
                      {open ? (
                        <ExpandLess sx={{ ml: 'auto' }} />
                      ) : (
                        <ExpandMore sx={{ ml: 'auto' }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit sx={{ ml: 3, mb: 2 }}>
                    {children.map(({ url, name, icon }, index) => (
                      <Link prefetch={false} key={index} href={url} passHref legacyBehavior>
                        <ListItem>
                          <ListItemIcon>{icon}</ListItemIcon>
                          {name}
                        </ListItem>
                      </Link>
                    ))}
                  </Collapse>
                </React.Fragment>
              ) : (
                <Link prefetch={false} key={index} href={url} passHref legacyBehavior>
                  <ListItem>
                    <ListItemIcon>{icon}</ListItemIcon>
                    {name}
                  </ListItem>
                </Link>
              );
            })}
            {Boolean(additionalLinks?.length) && <Divider />}
            <Link prefetch={false} href={paths.profile.root} passHref legacyBehavior>
              <MenuItem>
                <ListItemIcon>
                  <ProfileIcon fontSize="medium" sx={{ color: '#52A7C9' }} />
                </ListItemIcon>
                Profile
              </MenuItem>
            </Link>
            <Link prefetch={false} href={paths.my_events} passHref legacyBehavior>
              <MenuItem>
                <ListItemIcon>
                  <EventIcon fontSize="medium" sx={{ color: '#52A7C9' }} />
                </ListItemIcon>
                My Events
              </MenuItem>
            </Link>
            <Link prefetch={false} href={paths.purchased_contents} passHref legacyBehavior>
              <MenuItem>
                <ListItemIcon>
                  <Paid fontSize="medium" sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                Purchased Contents
              </MenuItem>
            </Link>
            <Link prefetch={false} href={paths.my_ratings} passHref legacyBehavior>
              <MenuItem>
                <ListItemIcon>
                  <StarRateRounded fontSize="medium" sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                My Ratings
              </MenuItem>
            </Link>

            <Divider />
            {user?.isAffiliate ? (
              <Link prefetch={false} href={paths.affiliate_earnings} passHref legacyBehavior>
                <MenuItem>
                  <ListItemIcon>
                    <Paid fontSize="medium" sx={{ color: 'primary.main' }} />
                  </ListItemIcon>
                  Affiliate Tracking
                </MenuItem>
              </Link>
            ) : (
              <MenuItem
                onClick={() => {
                  setBecomeAffiliateModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AddCircleRounded fontSize="medium" />
                </ListItemIcon>
                Become an Affiliate Member
              </MenuItem>
            )}

            <CreateUserGroupButton />

            {Boolean(userGroup?.id) && (
              <Link
                prefetch={false}
                href={getUserGroupTypeLink(userGroup?.userGroupType, true) ?? ''}
                passHref
                legacyBehavior
              >
                <MenuItem>
                  <ListItemIcon>
                    <ManageAccounts fontSize="medium" />
                  </ListItemIcon>
                  Page Management
                </MenuItem>
              </Link>
            )}

            <Divider />

            <Link prefetch={false} href={paths.profile.settings.root} passHref legacyBehavior>
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="medium" />
                </ListItemIcon>
                Settings
              </MenuItem>
            </Link>

            <Divider />

            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Suspense>
        </Box>
      </Menu>
    </>
  );
}
