import { SharedService } from '@/services';

type ServicesType = {
  [key: string]: () => Promise<any>;
};

export const SERVICES: ServicesType = {
  countries: SharedService.getCountries,
  industryInterests: SharedService.getIndustryInterests,
  smeCategories: SharedService.getSMECategories,
  states: SharedService.getStates,
  userCategories: SharedService.getUserCategories,
  categories: SharedService.getCategories,
  timezones: SharedService.getTimezones,
  submissionRejectOptions: SharedService.getSubmissionRejectOptions,
  hearAboutUsItems: SharedService.getHearAboutUsItems,
  informationVideos: SharedService.getInformationVideos,
  reportPages: SharedService.getReportPages,
  reportTopics: SharedService.getReportTopics,
  strategicPartners: SharedService.getStrategicPartners,
  exploreSmeCategories: SharedService.getExploreSMECategories,
};
