// @mui
import { Tooltip, IconButton, Badge } from '@mui/material';
import { Chat as ChatIcon } from '@mui/icons-material';
// react
import { Reducer, useCallback, useEffect, useReducer, useState } from 'react';
// services
import { DashboardMessagesService } from '@/services';
// hooks
import { useAuth } from '@/hooks';
// next
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';
//
const ChatPopover = dynamic(() => import('./ChatPopover'));

// ----------------------------------------------------------------

enum t {
  LEFT_SIDE_USER = 'LEFT_SIDE_USER',
  LIST_MESSAGES = 'LIST_MESSAGES',
  REMOVE_MESSAGES = 'REMOVE_MESSAGES',
}

const initialChatState = {
  leftSideUserList: [],
  messagesList: [],
};

type ChatState = {
  leftSideUserList?: any[];
  messagesList?: any[];
};

type ChatAction = {
  type: t;
  payload?: any[];
};

const ChatReducer = (state: ChatState = initialChatState, action: ChatAction) => {
  switch (action.type) {
    case t.LEFT_SIDE_USER:
      return {
        ...state,
        leftSideUserList: action.payload,
      };
    case t.LIST_MESSAGES:
      return {
        ...state,
        messagesList: action.payload,
      };
    case t.REMOVE_MESSAGES:
      return {
        ...state,
        messagesList: [],
      };
    default:
      return { ...state };
  }
};

// ----------------------------------------------------------------

export default function Chat() {
  const { user } = useAuth();

  const pathname = usePathname();

  const [anchorEl, setAnchorEl] = useState(null);

  const [currentMessageObject, setCurrentMessageObject] = useState<any>(null);
  const [newMessageExists, setNewMessageExists] = useState([]);

  const [chatState, chatDispatch] = useReducer<Reducer<ChatState, ChatAction>>(
    ChatReducer,
    initialChatState
  );

  const getLeftSideUsers = useCallback(async () => {
    const { success, data } = await DashboardMessagesService.getLeftSideUsers();
    if (success) {
      let messageHeaders: any[];
      try {
        messageHeaders = JSON.parse(localStorage?.getItem('messageHeaders') ?? '');
      } catch (e) {
        messageHeaders = [];
      }
      if (!messageHeaders?.length && data?.length)
        setNewMessageExists(data?.map(({ id }: { id: number }) => id));
      else {
        const newMessageState = data.reduce((newMessageExists: number[], header: any) => {
          if (header.latestMessageSenderId === user?.id) return newMessageExists;
          if (!messageHeaders?.filter(({ id }) => id === header.id)?.length) {
            return [...newMessageExists, header.id];
          }
          if (
            messageHeaders.some(
              ({ id, latestMessageId }) =>
                id === header.id && latestMessageId !== header.latestMessageId
            )
          ) {
            return [...newMessageExists, header.id];
          }
          return newMessageExists;
        }, []);
        setNewMessageExists(newMessageState);
      }

      chatDispatch({
        type: t.LEFT_SIDE_USER,
        payload: data,
      });
    }
  }, [user?.id]);

  const getMessagesList = useCallback(async (messageHeaderId: number) => {
    if (typeof messageHeaderId === 'undefined') {
      return;
    }
    const { success, data } = await DashboardMessagesService.getMessagesList(messageHeaderId);
    if (success) {
      chatDispatch({
        type: t.LIST_MESSAGES,
        payload: data,
      });
    }
  }, []);

  useEffect(() => {
    getLeftSideUsers();
    if (anchorEl) {
      const chatInterval = setInterval(() => {
        getLeftSideUsers();
      }, 60000);
      return () => clearInterval(chatInterval);
    }
  }, [anchorEl, getLeftSideUsers, pathname]);

  useEffect(() => {
    if (currentMessageObject?.id) {
      const id = currentMessageObject.id;
      getMessagesList(Number(id));
    }
  }, [currentMessageObject, getMessagesList]);

  useEffect(() => {
    setCurrentMessageObject(null);
    chatDispatch({
      type: t.REMOVE_MESSAGES,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const handleChatClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ChatPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        chatState={chatState}
        getMessagesList={getMessagesList}
        getLeftSideUsers={getLeftSideUsers}
        newMessageExists={newMessageExists}
      />
      <Tooltip title="Chat">
        <span>
          <IconButton onClick={handleChatClick}>
            <Badge
              color="error"
              overlap="circular"
              badgeContent={newMessageExists?.length && !anchorEl ? newMessageExists?.length : 0}
            >
              <ChatIcon />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
