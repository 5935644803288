export enum CONTENT_TYPE {
  VIDEO = 1,
  MEETING = 2,
  WEBINAR = 3,
  LIVE = 4,
  ONE_ON_ONE = 5,
  PROGRAM = 6,
  PITCH_EVENT = 7,
  SERVICE = 8,
  PRODUCT = 9,
  TRAINING_SESSION = 10,
  PRODUCT_PACKAGE = 11,
}
