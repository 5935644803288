import { BaseService } from './BaseService';
class ContentsService extends BaseService {
  postSearchContents({
    searchText,
    page = 1,
    size = 10,
    categoryId = null,
    orderBy = 1,
    hasPastEvents = false,
    type = null,
    hasSubContents = true,
    searchBy = 1,
  }) {
    return this.post(
      `search-contents`,
      {
        searchText,
        categoryId,
        orderBy,
        hasPastEvents,
        type,
        hasSubContents,
        searchBy,
      },
      { params: { page, size } }
    );
  }
  getExploreEvents() {
    return this.get('explore-events');
  }
  getLatestEvents() {
    return this.get('latest-events');
  }
  getDetail(contentId) {
    return this.get(`${contentId}/detail`);
  }
  getDetailMeta(contentId) {
    return this.get(`${contentId}/detail/meta`);
  }
  getSuggestions(contentId) {
    return this.get(`${contentId}/suggestions`);
  }
  getComments(contentId, page = 1, size = 10) {
    return this.get(`${contentId}/comments`, { params: { page, size } });
  }
  getRelatedCategories() {
    return this.get(`related-categories`);
  }
  postComments(contentId, comment) {
    return this.post(`${contentId}/comments`, { comment });
  }
  putLike(contentId) {
    return this.put(`${contentId}/like`);
  }
  putReminder(contentId) {
    return this.put(`${contentId}/reminder`);
  }
  putConfirmScheduledMeeting(contentId) {
    return this.put(`${contentId}/confirm-scheduled-meeting`);
  }
  postBuy(contentId, code) {
    return this.post(
      `${contentId}/buy`,
      {},
      {
        params: {
          ...(code && { code }),
        },
      }
    );
  }
  getMeetingsStart(eventId) {
    return this.get(`${eventId}/meetings/start`);
  }
  getMeetingsJoin(eventId, code) {
    return this.get(`${eventId}/meetings/join`, {
      params: {
        ...(code && { code }),
      },
    });
  }
  getRatesSummary(userGroupId) {
    return this.get(`${userGroupId}/rates-summary`);
  }
  postRates(userGroupId, data) {
    return this.post(`${userGroupId}/rates`, data);
  }
  getRates(userGroupId, page = 1, size = 10) {
    return this.get(`${userGroupId}/rates`, {
      params: {
        page,
        size,
      },
    });
  }
}
export default new ContentsService('contents');
