// services
import { ContentsService } from '@/services';
// redux
import { modalActions } from '@/redux/slices/modal/modal';
import { dispatch } from '@/redux/store';

export const UNSCHEDULED_MEETING = 'UNSCHEDULED_MEETING';

export async function unscheduledMeeting(notificationId, params) {
  const { contentId } = params;
  const { success, data } = await ContentsService.getDetail(contentId);
  if (success) {
    dispatch(
      modalActions.SET_CALENDLY_SCHEDULE_MEETING({
        open: true,
        props: {
          calendlyLink: data?.service?.calendlyLink ?? data?.oneOnOne?.calendlyLink,
          contentId,
          registered: true,
          onSuccess: async () => {
            await ContentsService.putConfirmScheduledMeeting(contentId);
          },
        },
      })
    );
  }
}
