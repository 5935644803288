// @mui
import { Stack, Tooltip } from '@mui/material';
import { Circle, Groups } from '@mui/icons-material';
// change-case
import { sentenceCase } from 'change-case';
// utils
import getStatusColor from '@/utils/ui/getStatusColor';
import getUserGroupTypeLabel from '@/utils/userGroups/getUserGroupTypeLabel';
// enums
import { STATUS, USER_GROUP_MEMBER_TYPE } from '@/enums';
import Iconify from '@/components/minimal/iconify';
// contexts
import { AuthUserUserGroup } from '@/contexts/AuthContext';

type UserGroupItemProps = {
  userGroup: AuthUserUserGroup;
  selected?: boolean;
};

export default function UserGroupItem({ userGroup, selected = false }: UserGroupItemProps) {
  const { status, userGroupMemberType, title, userGroupType } = userGroup;
  return (
    <Stack direction="row" alignItems="center">
      <Stack
        sx={{
          position: 'relative',
          width: 24,
          height: 24,
          border: '1px solid #cecece',
          borderRadius: '50%',
        }}
        alignItems="center"
        justifyContent="center"
        mr={1}
      >
        <Tooltip
          title={`Page status is ${sentenceCase(STATUS[status] ?? '')}.`}
          PopperProps={{ sx: { zIndex: 9999 } }}
        >
          <Circle
            color={getStatusColor(status) as any}
            sx={{
              width: '10px',
              height: '10px',
              position: 'absolute',
              right: 0,
              bottom: 0,
              m: 0,
              transform: 'translate(2px,2px)',
            }}
          />
        </Tooltip>
        {userGroupMemberType === USER_GROUP_MEMBER_TYPE.OWNER && (
          <Tooltip title={`You are owner of this page.`} PopperProps={{ sx: { zIndex: 99999 } }}>
            <div style={{ width: '20px', height: '20px' }}>
              <Iconify icon="mdi:shield-home-outline" sx={{ fontSize: 20 }} />
            </div>
          </Tooltip>
        )}
        {userGroupMemberType === USER_GROUP_MEMBER_TYPE.TEAM_MEMBER && (
          <Tooltip title={`You are member of this page.`} PopperProps={{ sx: { zIndex: 99999 } }}>
            <Groups fontSize="medium" sx={{ m: 0 }} />
          </Tooltip>
        )}
      </Stack>
      {title}
      {selected && ' (' + getUserGroupTypeLabel(userGroupType) + ')'}
    </Stack>
  );
}
