// enums
import { STATUS } from '@/enums';

export default function getStatusColor(status) {
  switch (status) {
    case STATUS.ACTIVE:
      return 'success';
    case STATUS.PENDING:
      return 'warning';
    case STATUS.PASSIVE:
    case STATUS.REJECTED:
      return 'error';
    default:
      return '';
  }
}
