// utils
import { setUserGroup } from '@/utils/userGroup';
// enums
import { USER_GROUP_TYPE } from '@/enums';
// redux
import { remove } from '@/redux/slices/notification/utils';
// constants
import { paths } from '@/routes/paths';

export const COMPLETE_PAGE = 'COMPLETE_PAGE';

export function completePage(notificationId, { userGroupId, userGroupType }, user) {
  const userGroup = user?.userGroups?.find(({ id }) => id == userGroupId);
  if (!userGroup) return;
  setUserGroup(userGroup);
  remove(notificationId);
  switch (userGroupType) {
    case USER_GROUP_TYPE.INVESTOR:
      window.location = paths.page_management.investor.builder;
      break;
    case USER_GROUP_TYPE.STARTUP:
      window.location = paths.page_management.startup.builder;
      break;
    case USER_GROUP_TYPE.SME:
      window.location = paths.page_management.sme.builder;
      break;
    case USER_GROUP_TYPE.INSTITUTION:
      window.location = paths.page_management.institution.builder;
      break;
    case USER_GROUP_TYPE.INCUBATOR:
      window.location = paths.page_management.incubator.builder;
      break;
    default:
      return '';
  }
}
