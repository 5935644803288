'use client';

// react
import { Reducer, createContext, useCallback, useEffect, useReducer } from 'react';
// utils
import { setSession } from '@/utils/jwt';
import { setUserGroup } from '@/utils/userGroup';
// services
import { AuthPostSignUpRequestDto, AuthService, DashboardUserService } from '@/services';
// cookies-next
import { getCookie } from 'cookies-next';
// next
import { useRouter } from 'next/navigation';
// notifications
import { useNotification } from '@/notifications';
// enums
import { USER_GROUP_TYPE, USER_GROUP_MEMBER_TYPE } from '@/enums';
//
import { AuthState, AuthAction, AuthProviderProps, AuthContextProps } from './types';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoading: true,
};

const handlers = {
  INITIALIZE: (state: AuthState, action: AuthAction): AuthState => {
    const { isAuthenticated = false, user = null } = action.payload ?? {};
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isLoading: false,
    };
  },
  LOADING: (state: AuthState, action: AuthAction): AuthState => {
    const { isLoading = false } = action.payload ?? {};
    return {
      ...state,
      isLoading,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: AuthState, action: AuthAction) =>
  handlers[action.type] ? handlers[action.type](state as AuthState, action) : state;

const AuthContext = createContext<AuthContextProps>({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve({ success: false }),
  loginAsUser: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(false),
  syncUserInfo: () => Promise.resolve(),
  linkedinAuthenticate: () =>
    Promise.resolve({
      success: false,
      data: {
        accessToken: '',
      },
    }),
  googleAuthentication: () =>
    Promise.resolve({
      success: false,
      data: {
        accessToken: '',
      },
    }),
  forgotPassword: () => Promise.resolve({ success: false }),
});

// ----------------------------------------------------------------------

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(reducer, initialState);
  const { push, refresh } = useRouter();
  const { clearAll } = useNotification();

  const initUser = useCallback(async () => {
    const { success: userRequestSuccess, ...userResponse } = await DashboardUserService.getUser();
    const { success: userGroupsRequestSuccess, ...userGroupsResponse } =
      await DashboardUserService.getUserGroups();

    if (userRequestSuccess && userGroupsRequestSuccess) {
      const {
        id,
        detail,
        userGroupMembers,
        industryInterests,
        userCategory,
        notificationEmail,
        notificationPlatform,
        isAffiliate,
      } = userResponse?.data ?? {
        id: 0,
        detail: {},
        userGroupMembers: [],
        industryInterests: [],
        userCategory: {
          id: 0,
          title: '',
          userGroupType: USER_GROUP_TYPE.STARTUP,
        },
        notificationEmail: false,
        notificationPlatform: false,
        isAffiliate: false,
      };

      const startups =
        userGroupMembers?.filter(
          ({ userGroup, userGroupMemberType }) =>
            userGroup.userGroupType === USER_GROUP_TYPE.STARTUP &&
            userGroupMemberType === USER_GROUP_MEMBER_TYPE.OWNER
        ) ?? [];

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: {
            id,
            ...detail,
            userGroupMembers,
            industryInterests,
            userCategory,
            notificationEmail,
            notificationPlatform,
            isAffiliate,
            userGroups:
              userGroupsResponse?.data?.map(({ userGroupMemberType, userGroup }) => ({
                userGroupMemberType,
                ...userGroup,
              })) ?? [],
            ...(startups?.length > 0 && { startupId: startups[0]?.userGroup?.id }),
            ...(startups?.length > 0 && { startup: startups[0]?.userGroup }),
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    const initialize = () => {
      const accessToken = getCookie('accessToken');

      if (accessToken) {
        dispatch({
          type: 'LOADING',
          payload: {
            isLoading: true,
          },
        });
        setSession(accessToken);
        initUser();
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [initUser]);

  const login = useCallback(
    async (email: string, password: string, recaptchaToken?: string) => {
      const { success, data } = await AuthService.postLogin({
        email,
        password,
        recaptchaToken,
      });
      if (success) {
        setSession(data?.accessToken);
        setUserGroup();
        initUser();
      }
      return { success };
    },
    [initUser]
  );

  const linkedinAuthenticate = useCallback(
    async (code: string, recaptchaToken?: string) => {
      const { success, data } = await AuthService.postLinkedinAuthentication(code, recaptchaToken);
      if (success) {
        setSession(data?.accessToken);
        setUserGroup();
        initUser();
        refresh();
        return { success, data };
      }
      return { success: false };
    },
    [initUser, refresh]
  );

  const googleAuthentication = useCallback(
    async (token: string, recaptchaToken?: string) => {
      const { success, data } = await AuthService.postGoogleAuthentication(token, recaptchaToken);
      if (success) {
        setSession(data?.accessToken);
        setUserGroup();
        initUser();
        refresh();
        return { success, data };
      }
      return { success: false };
    },
    [initUser, refresh]
  );

  const syncUserInfo = useCallback(async () => {
    return initUser();
  }, [initUser]);

  const signup = useCallback(
    async (userData: AuthPostSignUpRequestDto, recaptchaToken?: string) => {
      const { success, data } = await AuthService.postSignup(userData, recaptchaToken);
      if (success) {
        setSession(data?.accessToken);
        setUserGroup();
        initUser();
      }
      return success;
    },
    [initUser]
  );

  const forgotPassword = useCallback(async (email: string, recaptchaToken?: string) => {
    return await AuthService.postForgotPassword(email, recaptchaToken);
  }, []);

  const loginAsUser = useCallback(
    async (accessToken: string) => {
      setSession(accessToken);
      setUserGroup();
      await initUser();
    },
    [initUser]
  );

  const logout = useCallback(async () => {
    push('/');
    setSession(null);
    setUserGroup(null);
    clearAll();
    dispatch({ type: 'LOGOUT' });
  }, [clearAll, push]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginAsUser,
        logout,
        signup,
        syncUserInfo,
        linkedinAuthenticate,
        googleAuthentication,
        forgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
