// constants
import { paths } from '@/routes/paths';
// utils
import { setUserGroup } from '@/utils/userGroup';

export const SHOW_PENDING_SUBMISSIONS = 'SHOW_PENDING_SUBMISSIONS';

export function showPendingSubmissions(notificationId, { userGroupId }, user) {
  const userGroup = user?.userGroups?.find(({ id }) => id == userGroupId);
  if (!userGroup) return;
  setUserGroup(userGroup);
  window.location = paths.page_management.investor.submissions.root;
}
