// utils
import getContentHref from '@/utils/contents/getContentHref';

export const INVITED_TO_EVENT = 'INVITED_TO_EVENT';
export const INVITED_TO_SERVICE = 'INVITED_TO_SERVICE';
export const INVITED_TO_PROGRAM = 'INVITED_TO_PROGRAM';

export function invitedToContent(notificationId, params) {
  const { contentId, contentType } = params;

  window.location = getContentHref(contentId, contentType);
}
