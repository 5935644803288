// redux
import { ModalState } from '@/redux/slices/modal';
import { useSelector } from '@/redux/store';

const useModal = () => {
  const state = useSelector((state) => state.modal) as ModalState;

  if (!state) throw new Error('ModalContainer is not loaded!');

  return state;
};

export default useModal;
