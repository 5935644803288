// prop-types
import PropTypes from 'prop-types';
// @mui
import { Typography, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
// utils
import { fToNow } from '@/utils/formatTime';
// components
import Iconify from '@/components/minimal/iconify';
// notifications
import { useNotification } from '@/notifications';
// hooks
import { useAuth } from '@/hooks';
//
import getNotificationAction from '../actions/getNotificationAction';

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    icon: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    permanent: PropTypes.bool,
    readAt: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  handleClose: PropTypes.func,
};

export default function NotificationItem({ notification, handleClose }) {
  const { id, title, icon, createdAt, readAt, action, actionParams, notRemote } = notification;
  const { read } = useNotification();
  const { user } = useAuth();

  const handleClick = async () => {
    await getNotificationAction(action)(id, actionParams, user);
    if (!readAt && id) read(id, notRemote);
    handleClose();
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!readAt && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Iconify icon={icon ?? 'clarity:new-solid'} sx={{ fontSize: 24, color: 'primary.main' }} />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle2">{title}</Typography>}
        secondary={
          Boolean(createdAt) && (
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
              {fToNow(createdAt)}
            </Typography>
          )
        }
      />
    </ListItemButton>
  );
}
