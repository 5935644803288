// constants
import { paths } from '@/routes/paths';
// utils
import { setUserGroup } from '@/utils/userGroup';

export const ADDED_TO_WATCH_LIST = 'ADDED_TO_WATCH_LIST';

export function addedToWatchList(notificationId, params, user) {
  const { userGroupId } = params;
  const userGroup = user?.userGroups?.find(({ id }) => id == userGroupId);
  if (!userGroup) return;
  setUserGroup(userGroup);
  window.location = `${paths.page_management.startup.submissions}`;
}
