// constants
import { paths } from '@/routes/paths';

export const INVESTOR_QUESTIONS_UPDATE = 'INVESTOR_QUESTIONS_UPDATE';

export function investorQuestionsUpdate(notificationId, params) {
  const { userGroupId } = params;

  window.location = `${paths.investors.root}/${userGroupId}`;
}
