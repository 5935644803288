// prop-types
import PropTypes from 'prop-types';
// react
import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { Box } from '@mui/material';
// components
import { Image } from '@/components/common';
// assets
import { scaleDarkLogo } from 'assets/img';

// ----------------------------------------------------------------------

const ScaleupstreamDarkLogo = forwardRef(
  ({ disabledLink = false, sx, width = 120, height = 60 }, ref) => {
    const logo = (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          cursor: 'pointer',
          ...sx,
        }}
      >
        <Image src={scaleDarkLogo} alt={'ScaleupStream'} width={width} height={height} />
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <NextLink href="/" prefetch={false}>
        {logo}
      </NextLink>
    );
  }
);

ScaleupstreamDarkLogo.propTypes = {
  disabledLink: PropTypes.bool,
  isCollapse: PropTypes.bool,
  sx: PropTypes.object,
};

ScaleupstreamDarkLogo.displayName = "ScaleupstreamDarkLogo"

export default ScaleupstreamDarkLogo;
