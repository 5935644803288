// redux
import { useSelector } from '@/redux/store';
import { NotificationState } from '@/redux/slices/notification';
import {
  setNotificationList,
  push,
  read,
  readAll,
  clearAll,
} from '@/redux/slices/notification/utils';
import { remove } from 'lodash';

const useNotification = () => {
  const state = useSelector((state) => state.notification) as NotificationState;

  if (!state) throw new Error('Notification state must be use inside ReduxProvider');

  return { ...state, setNotificationList, push, remove, read, readAll, clearAll };
};

export default useNotification;
