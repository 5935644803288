// @mui
import { Card, CardContent, CardHeader, Chip } from '@mui/material';

export default function SubmissionItem({ submission }) {
  const { title, evaluation } = submission;

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Chip
          sx={{ width: 70, color: 'white' }}
          color={
            evaluation === 'A'
              ? 'success'
              : evaluation === 'B'
              ? 'warning'
              : evaluation === 'C'
              ? 'error'
              : 'error'
          }
          label={evaluation}
        />
      </CardContent>
    </Card>
  );
}
