import BaseService from '../BaseService';
import {
  SharedCategoryDataDto,
  SharedCountryDataDto,
  SharedExploreSMECategoryDataDto,
  SharedHearAboutUsItemDataDto,
  SharedIndustryInterestDataDto,
  SharedInformationVideoDataDto,
  SharedReportPageDataDto,
  SharedReportTopicDataDto,
  SharedSMECategoryDataDto,
  SharedStateDataDto,
  SharedStrategicPartnerDataDto,
  SharedSubmissionRejectOptionDataDto,
  SharedTimezoneDataDto,
  SharedUserCategoryDataDto,
  SliderDataDto,
} from './dto/';

class SharedService {
  getIndustryInterests() {
    return BaseService.get<SharedIndustryInterestDataDto[]>(`shared/industry-interests`);
  }
  getSMECategories() {
    return BaseService.get<SharedSMECategoryDataDto[]>(`shared/sme-categories`);
  }
  getCountries() {
    return BaseService.get<SharedCountryDataDto[]>(`shared/countries`);
  }
  getStates() {
    return BaseService.get<SharedStateDataDto[]>(`shared/states`);
  }
  getUserCategories() {
    return BaseService.get<SharedUserCategoryDataDto[]>('shared/user-categories');
  }
  getCategories() {
    return BaseService.get<SharedCategoryDataDto[]>('shared/categories');
  }
  getSliders() {
    return BaseService.get<SliderDataDto[]>('shared/sliders');
  }
  getTimezones() {
    return BaseService.get<SharedTimezoneDataDto[]>(`shared/timezones`);
  }
  getSubmissionRejectOptions() {
    return BaseService.get<SharedSubmissionRejectOptionDataDto[]>(
      `shared/submission-reject-options`
    );
  }
  getHearAboutUsItems() {
    return BaseService.get<SharedHearAboutUsItemDataDto[]>(`shared/hear-about-us-items`);
  }
  getInformationVideos() {
    return BaseService.get<SharedInformationVideoDataDto[]>(`shared/information-videos`);
  }
  getReportPages() {
    return BaseService.get<SharedReportPageDataDto[]>(`shared/report-pages`);
  }
  getReportTopics() {
    return BaseService.get<SharedReportTopicDataDto[]>(`shared/report-topics`);
  }
  getInvestorQuestions(page: number, size: number, data: any) {
    return BaseService.post(`shared/investor-questions`, data, { params: { page, size } });
  }
  getStrategicPartners() {
    return BaseService.get<SharedStrategicPartnerDataDto[]>(`shared/strategic-partners`);
  }
  getExploreSMECategories() {
    return BaseService.get<SharedExploreSMECategoryDataDto[]>(`smes/explore/sme-categories`);
  }
}

export default new SharedService();
