export const SUBMISSIONS_STATUS = {
  PASSIVE: 0,
  ACCEPTED: 1,
  UNDECIDED: 3,
  REJECTED: 5,
  WATCH_LIST: 6,
  0: 'PASSIVE',
  1: 'ACCEPTED',
  3: 'UNDECIDED',
  5: 'REJECTED',
  6: 'WATCH_LIST',
};
