import format from 'date-fns/format';
import getTime from 'date-fns/getTime';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formatDateTime(date) {
  return format(new Date(date), 'yyyy, iii, MMM d, p');
}

export function formatDate(date) {
  return format(date, 'EEE, LLL d, yyyy');
}
